import awsConfig from './cfn-output.json';
import { parseAmplifyConfig } from 'aws-amplify/utils';

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: awsConfig.UserPoolId,
      userPoolClientId: awsConfig.UserPoolClientId,
      userPoolEndpoint: awsConfig.UserPoolEndpoint,
      identityPoolId: awsConfig.IdentityPoolId,
      allowGuestAccess: true,
    },
  },
  Storage: {
    S3: {
      bucket: awsConfig.MediaServiceBucketName,
      region: 'us-east-1',
    },
  },
  API: {
    GraphQL: {
      endpoint: awsConfig.GraphQLApiURL,
      region: 'us-east-1',
      defaultAuthMode: 'lambda',
    },
  },
} satisfies Parameters<typeof parseAmplifyConfig>[0];
