import { amplifyConfig } from '../amplify-config';
import awsConfig from '../cfn-output.json';

export const AMPLIFY_CONFIG = amplifyConfig;
export const AWS_GRAPHQL_API_URL = awsConfig.GraphQLApiURL;
export const AWS_REGION = 'us-east-1';
export const AWS_COGNITO_USER_POOL_ENDPOINT = awsConfig.UserPoolEndpoint;
export const AWS_S3_BUCKET_NAME = awsConfig.MediaServiceBucketName;
export const MEDIA_SERVICE_DOMAIN_NAME = awsConfig.MediaServiceDomainName;
export const AWS_JOIN_WAITLIST_LAMBDA_URL = awsConfig.JoinWaitlistLambdaUrl;
export const OAUTH_CLIENT_ID = 'bjBzUncxeDkxcE04RzM3MUpYWHM6MTpjaQ';
export const API_KEY = awsConfig.ApiKey;
export const POST_PREVIEW_URL = awsConfig.PostImageLambdaUrl;
export const APP_URL = awsConfig.AppUrl;

export const WITH_WAITLIST = false;

export const WITH_DARK_MODE = import.meta.env.MODE !== 'production';
